<script>
export default {
  name: 'UnlockReasonsEnum',
  props: {
    reason: {
      type: String,
      required: false,
      default: ''
    },
  },
  render(h) {
    return h('span',{}, this.reason
      ? this.$t(`fleetProfile.unlockReasons.${this.reason}`)
      : '-' );
  }
}
</script>
