<script>
export default {
  name: 'LockReasonsEnum',
  props: {
    reason: {
      type: String,
      required: false,
      default: '',
    },
  },
  render(h) {
    return h('span',{}, this.reason
      ? this.$t(`fleetProfile.lockReasons.${this.reason}`)
      : '-' );
  }
}
</script>
