var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalBlockHistoryEvents",
        size: "lg",
        title: "Histórico dos eventos",
        "ok-title": "Fechar",
        "ok-only": "",
      },
      on: {
        hide: function ($event) {
          _vm.mapOpen = false
        },
        hidden: function ($event) {
          _vm.mapOpen = false
        },
      },
    },
    [
      _c("p", { staticClass: "card-text" }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(" " + _vm._s(_vm.$t("fleetProfile.tableHeaders.car")) + " "),
        ]),
        _c("br"),
        _c("strong", [_vm._v(" " + _vm._s(_vm.carLicensePlate || "-") + " ")]),
      ]),
      _c("p", { staticClass: "card-text" }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("fleetProfile.tableHeaders.lock_request_by")) +
              " "
          ),
        ]),
        _c("br"),
        _c("strong", [
          _vm._v(" " + _vm._s(_vm.operationData.lock_request_by || "-") + " "),
        ]),
      ]),
      _c("p", { staticClass: "card-text" }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("fleetProfile.tableHeaders.lock_reason")) + " "
          ),
        ]),
        _c("br"),
        _c(
          "strong",
          [
            _c("lock-reasons-enum", {
              attrs: { reason: _vm.operationData.lock_reason || "" },
            }),
          ],
          1
        ),
      ]),
      _c("p", { staticClass: "card-text" }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("fleetProfile.tableHeaders.unlock_request_by")) +
              " "
          ),
        ]),
        _c("br"),
        _c("strong", [
          _vm._v(
            " " + _vm._s(_vm.operationData.unlock_request_by || "-") + " "
          ),
        ]),
      ]),
      _c("p", { staticClass: "card-text" }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("fleetProfile.tableHeaders.unlock_reason")) +
              " "
          ),
        ]),
        _c("br"),
        _c(
          "strong",
          [
            _c("unlock-reasons-enum", {
              attrs: { reason: _vm.operationData.unlock_reason || "" },
            }),
          ],
          1
        ),
      ]),
      _c("b-table", {
        attrs: {
          responsive: "",
          striped: "",
          hover: "",
          sortable: "",
          items: _vm.fleetEvents,
          fields: _vm.fields,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(status)",
            fn: function (data) {
              return [
                _c("block-histories-status-badge", {
                  attrs: { status: data.item.status, version: _vm.getVersion },
                }),
              ]
            },
          },
          {
            key: "cell(created_at)",
            fn: function (data) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("format_date_tz")(
                        data.item.created_at,
                        "DD/MM/YYYY HH:mm"
                      )
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(edit)",
            fn: function (data) {
              return [
                data.item && data.item.tracking
                  ? _c(
                      "span",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.showMap(data)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("fleetProfile.buttons.showMap")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-collapse",
        {
          staticClass: "mt-2",
          attrs: { id: "map-collapse" },
          model: {
            value: _vm.mapOpen,
            callback: function ($$v) {
              _vm.mapOpen = $$v
            },
            expression: "mapOpen",
          },
        },
        [
          _c("kovi-map-by-geometry", {
            attrs: { id: "block-history-events", tracking: _vm.mapTracking },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }