<template>
  <layout>
    <fleet-header :id="$route.params.id" />
    <custom-data-table
      :fields="fields"
      :filters="filters"
      :fixed-filters="{ car: $route.params.id }"
      :per-page="50"
      :query="fleet_operations_list"
      :sort="[['created_at', 'DESC']]"
      query-node="fleetOperations"
      @vuetable:row-clicked="$_goTo"
    >
      <lock-reasons-enum
        slot="lock_reason"
        slot-scope="props"
        :reason="props.rowData.lock_reason"
      />

      <span
        slot="lock_request_by"
        slot-scope="props"
      >
        {{ props.rowData.lock_request_by || '-' }}
      </span>

      <span
        slot="created_at"
        slot-scope="props"
      >
        {{ props.rowData.created_at | format_date_tz('DD/MM/YYYY HH:mm') }}
      </span>

      <unlock-reasons-enum
        slot="unlock_reason"
        slot-scope="props"
        :reason="props.rowData.unlock_reason || ''"
      />

      <span
        slot="unlock_request_by"
        slot-scope="props"
      >
        {{ props.rowData.unlock_request_by || '-' }}
      </span>

      <span
        slot="updated_at"
        slot-scope="props"
      >
        {{ props.rowData.updated_at | format_date_tz('DD/MM/YYYY HH:mm') }}
      </span>

      <span
        slot="status"
        slot-scope="props"
      >
        <block-histories-status-badge
          :status="props.rowData.status"
        />
      </span>
    </custom-data-table>
    <modal-block-history-events
      :fleet-operation-status="fleetOperationStatusObject"
    />
  </layout>
</template>

<script>
import Layout from '@layouts/main';
import FleetHeader from '@components/fleet/header';
import CustomDataTable from '@components/CustomDataTable';
import ModalBlockHistoryEvents from '@components/modals/block-history';
import { fleetOperationStatusEnum } from '@utils/fleet';
import FleetOperationsList from '@graphql/car/queries/fleetoperations-list.graphql';
import FleetOperationsModal from '@graphql/car/queries/fleetoperations-modal.graphql';
import BlockHistoriesStatusBadge from '@views/fleet/common/status-badge';
import LockReasonsEnum from '@views/fleet/common/lock-reasons-enums';
import UnlockReasonsEnum from '@views/fleet/common/unlock-reasons-enums';

export default {
  name: 'BlockHistories',
  components: {
    UnlockReasonsEnum,
    LockReasonsEnum,
    BlockHistoriesStatusBadge,
    Layout,
    FleetHeader,
    CustomDataTable,
    ModalBlockHistoryEvents,
  },
  data () {
    return {
      fleetOperationStatusObject: {},
      fleet_operations_list: FleetOperationsList,
      fields: [
        {
          name: 'lock_reason',
          title: this.$t('fleetProfile.tableHeaders.lock_reason'),
          sortField: 'lock_reason'
        },
        {
          name: 'lock_request_by',
          title: this.$t('fleetProfile.tableHeaders.lock_request_by'),
          sortField: 'lock_request_by'
        },
        {
          name: 'created_at',
          title: this.$t('fleetProfile.tableHeaders.created_at'),
          sortField: 'created_at'
        },
        {
          name: 'unlock_reason',
          title: this.$t('fleetProfile.tableHeaders.unlock_reason'),
          sortField: 'unlock_reason'
        },
        {
          name: 'unlock_request_by',
          title: this.$t('fleetProfile.tableHeaders.unlock_request_by'),
          sortField: 'unlock_request_by'
        },
        {
          name: 'updated_at',
          title: this.$t('fleetProfile.tableHeaders.updated_at'),
          sortField: 'updated_at'
        },
        {
          name: 'status',
          title: this.$t('fleetProfile.tableHeaders.status'),
          sortField: 'status'
        },
      ],
    };
  },
  computed: {
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: false,
            placeholder: 'Busque por Tipo...',
          },
          graphqlQuery(value) {
            return {
              $or: [{
                  lock_reason: {
                    $like: `%${value}%`
                  }
                }
              ]
            };
          },
        },
        status: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Status',
            options: [{
                value: null,
                text: 'Todos'
              },
              ...fleetOperationStatusEnum
            ],
          },
          graphqlQuery(value) {
            return !value
              ? { status: fleetOperationStatusEnum.map((item) => item.value) }
              : { status: [value] };
          }
        }
      };
    }
  },
  methods: {
    $_goTo(_item = null) {
      _item = _item.data;
      return this.$apollo.query({
        query: FleetOperationsModal,
        variables: {
          "filters": {
            "where": {
              "id": _item.id
            }
          },
          "order": [
            "created_at",
            "DESC"
          ]
        }
      })
      .then((res) => {
        if(res.data.fleetOperations.items.length > 0) {
          this.fleetOperationStatusObject = res.data.fleetOperations;
          this.$root.$emit('bv::show::modal', 'modalBlockHistoryEvents');
        }else{
          this.$swal({
            type: 'info',
            title: `Sem dados para este evento`
          });
        }
      })
      .catch((err) => {
        this.$swal({
          type: 'error',
          title: `Ocorreu um erro na sua solicitação, tente novamente`,
          text: err
        });
      });
    },
  }
};
</script>
