<template>
  <div
    :class="mapName"
    style="height: 150px"
  />
</template>

<script>
//eslint-disable-next-line no-undef
const googleMaps = google;

export default {
  name: 'KoviMapByGeometry',
  props: {
    mapName: {
      type: String,
      required: false,
      default: 'map-by-geometry',
    },
    tracking: {
      type: Object,
      required: true,
      default: () => ({
        'speed': 0,
        'engine': false,
        'geometry': {
          'type': 'Point',
          'coordinates': [
            0,
            0
          ]
        }
      }),
    },
  },
  data() {
    return {
      key: 'value',
      mapOptions: {},
      fleet: false,
      map: {},
      marker: {},
    };
  },
  watch: {
    tracking() {
      this.init();
    },
  },
  computed: {
    coordinates() {
      return {
        lat: (this.tracking.geometry?.coordinates[0] || 0),
        lng: (this.tracking.geometry?.coordinates[1] || 0),
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const element = document.querySelector(`.${this.mapName}`);

      this.mapOptions = {
        center: this.coordinates,
        flat: true,
        fullscreenControl: true,
        mapTypeControl: false,
        mapTypeId: googleMaps.maps.MapTypeId.ROADMAP,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoom: 15,
        zoomControl: true,
      };

      this.map = new googleMaps.maps.Map(element, this.mapOptions);

      this.marker = new googleMaps.maps.Marker({
        position: this.coordinates,
        map: this.map,
      });
    }
  },
}
</script>
