<script>
export default {
  name: 'BlockHistoriesStatusBadge',
  props: {
    status: {
      type: String,
      required: false,
      default: ''
    },
    version: {
      type: Number,
      required: false,
      default: 0
    },
  },
  methods: {
    getStatus() {
      return this.version < 2
        ? this.$t(`fleetProfile.statusEnum.${this.status}`)
        : this.$t(`fleetProfile.statusEnumV2.${this.status}`)
    }
  },
  render(h) {
    return h('b-badge', {
      props: {
        variant: this.status,
      },
    },
      this.status
      ? this.getStatus()
      : '-');
  }
}
</script>

<style lang="sass" scoped>
.badge
  padding: 5px 10px
  color: white

  &-LOCKED
    background: brown
  &-LOCK_PENDING,
  &-UNLOCK_PENDING,
  &-UNLOCK_SENT_TO_PROVIDER,
  &-LOCK_SENT_TO_PROVIDER
    background: orange

  &-FINISHED,
  &-UNLOCKED
    background: green

  &-CANCELED
    background: black

  &-ERROR
    background: red
</style>
