export const fleetOperationStatusEnum = [
  { 
    text: 'Bloqueio pendente',
    value: 'LOCK_PENDING'
  },
  {
    text: 'Bloqueio enviado para o fornecedor',
    value: 'LOCK_SENT_TO_PROVIDER'
  },
  {
    text: 'Desbloqueio enviado para o fornecedor',
    value: 'UNLOCK_SENT_TO_PROVIDER'
  },
  {
    text: 'Desbloqueio pendente',
    value: 'UNLOCK_PENDING'
  },
  {
    text: 'Finalizado',
    value: 'FINISHED'
  },
  {
    text: 'Bloqueado',
    value: 'LOCKED'
  },
  {
    text: 'Erro',
    value: 'ERROR'
  }
];