<template>
  <b-modal
    id="modalBlockHistoryEvents"
    size="lg"
    title="Histórico dos eventos"
    ok-title="Fechar"
    ok-only
    @hide="mapOpen = false"
    @hidden="mapOpen = false"
  >
    <p class="card-text">
      <span class="text-muted">
        {{ $t('fleetProfile.tableHeaders.car') }}
      </span>
      <br>
      <strong>
        {{ carLicensePlate || '-' }}
      </strong>
    </p>
    <p class="card-text">
      <span class="text-muted">
        {{ $t('fleetProfile.tableHeaders.lock_request_by') }}
      </span>
      <br>
      <strong>
        {{ operationData.lock_request_by || '-' }}
      </strong>
    </p>
    <p class="card-text">
      <span class="text-muted">
        {{ $t('fleetProfile.tableHeaders.lock_reason') }}
      </span>
      <br>
      <strong>
        <lock-reasons-enum
          :reason="operationData.lock_reason || ''"
        />
      </strong>
    </p>
    <p class="card-text">
      <span class="text-muted">
        {{ $t('fleetProfile.tableHeaders.unlock_request_by') }}
      </span>
      <br>
      <strong>
        {{ operationData.unlock_request_by || '-' }}
      </strong>
    </p>
    <p class="card-text">
      <span class="text-muted">
        {{ $t('fleetProfile.tableHeaders.unlock_reason') }}
      </span>
      <br>
      <strong>
        <unlock-reasons-enum
          :reason="operationData.unlock_reason || ''"
        />
      </strong>
    </p>

    <b-table
      responsive
      striped
      hover
      sortable
      :items="fleetEvents"
      :fields="fields"
    >
      <template
        v-slot:cell(status)="data"
      >
        <block-histories-status-badge
          :status="data.item.status"
          :version="getVersion"
        />
      </template>

      <template
        v-slot:cell(created_at)="data"
      >
        <span>{{ data.item.created_at | format_date_tz('DD/MM/YYYY HH:mm') }}</span>
      </template>

      <template
        v-slot:cell(edit)="data"
      >
        <span
          v-if="data.item && data.item.tracking"
        >
          <b-button
            size="sm"
            @click="showMap(data)"
          >
            {{ $t('fleetProfile.buttons.showMap') }}
          </b-button>
        </span>
      </template>
    </b-table>

    <b-collapse id="map-collapse" v-model="mapOpen" class="mt-2">
      <kovi-map-by-geometry
        id="block-history-events"
        :tracking="mapTracking"
      />
    </b-collapse>
  </b-modal>
</template>

<script>
import BlockHistoriesStatusBadge from '@views/fleet/common/status-badge';
import UnlockReasonsEnum from '@views/fleet/common/unlock-reasons-enums';
import LockReasonsEnum from '@views/fleet/common/lock-reasons-enums';
import KoviMapByGeometry from '@components/shared/map-by-geometry';
import { mapGetters } from 'vuex';
export default {
  name: 'ModalBlockHistoryEvents',
  components: { KoviMapByGeometry, LockReasonsEnum, UnlockReasonsEnum, BlockHistoriesStatusBadge },
  props: {
    fleetOperationStatus: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      mapTracking: {
        'speed': 0,
        'engine': false,
        'geometry': {
          'type': 'Point',
          'coordinates': [
            0,
            0
          ]
        }
      },
      mapOpen: false,
      sortBy: 'created_at',
      sortDesc: false,
      fields: [
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Data',
          sortable: true
        },
        {
          key: 'edit',
          title: '',
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      profile: 'fleet/header',
    }),
    getVersion() {
      return parseInt(this.profile?.tracking?.version) || 0
    },
    carLicensePlate: {
      get() {
        if (!Object.keys(this.fleetOperationStatus)?.length) return [];

        const [{
          car,
        }] = this.fleetOperationStatus.items;

        return car ? car.license_plate : '';
      },
      cache: false,
    },
    operationData: {
      get() {
        if (!Object.keys(this.fleetOperationStatus)?.length) return [];

        const [{
          lock_reason,
          lock_request_by,
          unlock_reason,
          unlock_request_by,
        }] = this.fleetOperationStatus.items;

        return {
          lock_reason,
          lock_request_by,
          unlock_reason,
          unlock_request_by,
        };
      },
      cache: false,
    },
    fleetEvents: {
      get() {
        if (!Object.keys(this.fleetOperationStatus)?.length) return [];

        const [{
          events: {
            items
          }
        }] = this.fleetOperationStatus.items;

        return items;
      },
      cache: false,
    }
  },
  methods: {
    showMap(data) {
      if (!data.item?.tracking) return;
      this.mapTracking = data.item?.tracking;
      this.mapOpen = true;
    },
  },
};
</script>
<style lang="sass" scoped>
.badge
  padding: 5px 10px
  color: white

  &-LOCKED
    background: brown

  &-LOCK_PENDING,
  &-UNLOCK_PENDING,
  &-UNLOCK_SENT_TO_PROVIDER,
  &-LOCK_SENT_TO_PROVIDER
    background: orange

  &-FINISHED
    background: green

  &-CANCELED
    background: black

  &-ERROR
    background: red
</style>
